import React, { PureComponent } from "react";
import ErrorOutput from "./ErrorOutput";

class Reviews extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      generate: true
    };
  }

  showHide = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    const { show, generate } = this.state;
    const { data } = this.props;

    return (
      <section className="review">
        <h2>Reviews
          <span className="showItems"
                onClick={this.showHide}>{show ? "Hide" : "Show"}</span>
        </h2>
        <div className={show ? "" : "hide"}>
          {data && generate && (
            data.map((item, idx) => {
              return (
                <article key={idx}>
                  <p>{item}</p>
                </article>
              );
            })
          )}

          {!data && (
            <ErrorOutput data="There are no reviews right now"/>
          )}


        </div>
      </section>
    );
  }
}

export default Reviews;
