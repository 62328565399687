import React, { PureComponent } from "react";
import ErrorOutput from "./ErrorOutput";

class ImageList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      generate: true
    };
  }

  showHide = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    const { show, generate } = this.state;
    const { data, name } = this.props;
    return (
      <section className="imageList">
        <h2>Images
          <span className="showItems"
                onClick={this.showHide}>{show ? "Hide" : "Show"}</span>
        </h2>
        <div className={show ? "" : "hide"}>

          {data && generate && (
            data.map((item, idx) => {
              return (
                <div className="center mediaPreview" key={idx}>
                  <img src={item} alt={name} title={name}/>
                </div>
              );
            })
          )}

          {!data && (
            <ErrorOutput data="There are no images right now"/>
          )}
        </div>
      </section>
    );
  }
}

export default ImageList;
