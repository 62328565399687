import React from "react";

const ErrorOutput = props => {
  return (
    <div className="errorOutput center">
      <p>
        {props.data}
      </p>
    </div>
  );
};

export default ErrorOutput;
