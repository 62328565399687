import React, { PureComponent } from "react";
import ErrorOutput from "./ErrorOutput";

class VideoList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      generate: true
    };
  }

  showHide = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    const { show, generate } = this.state;
    const { data } = this.props;

    return (
      <section className="videoList">
        <h2>Videos <span className="showItems"
                         onClick={this.showHide}>{show ? "Hide" : "Show"}</span>
        </h2>
        <div className={show ? "" : "hide"}>
          {data && generate && (
            data.map((item, idx) => {
              return (
                <div className="center mediaPreview" key={idx}>
                  <video controls poster={item.poster}>
                    <source src={item.video}
                            type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'/>
                  </video>
                </div>
              );
            })
          )}

          {!data && (
            <ErrorOutput data="There are no videos right now"/>
          )}


        </div>
      </section>
    );
  }
}

export default VideoList;
