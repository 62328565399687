import React, { Component } from "react";
import { Link } from "gatsby";

import data from "../data";

import Layout from "../components/layout";
import Reviews from "../components/Reviews";
import ImageList from "../components/ImageList";
import VideoList from "../components/VideoList";

class SitePage extends Component {
  componentDidMount() {
    const sited = this.props.pageContext.item;
    document.title = sited.name + " - Virtual reality porno list";
    window.scrollTo(0, 0);
  }

  render() {
    const site = this.props.pageContext.item;

    return (
      <Layout>
        <section>
          <div className="itemType">
            <div className="goBack">
              <Link to={"/"}>
                Go back
              </Link>
            </div>
            {site && (
              <main>
                <Link to={`/link/${site.url}`} target='_blank'>
                  <h1 className="center">{site.name}</h1>
                </Link>
                <div className="center visitSite">
                  <Link to={`/link/${site.url}`} className="red" target='_blank'>
                    Visit Site
                  </Link>
                </div>

                <Reviews data={site.text}/>
                <ImageList data={site.image_preview} name={site.name}/>
                <VideoList data={site.videos}/>

              </main>
            )}

          </div>
        </section>
      </Layout>
    );
  }
}

export default SitePage;
